import { ArticleProvider } from 'src/contexts/ArticleContext';
import BlogDetail from 'src/pages/landing/components/hipros/blog/ArticleDetail';

export default function DetailArticle() {
  return (
    <ArticleProvider>
      <BlogDetail />
    </ArticleProvider>
  );
}
