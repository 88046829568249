import { Button } from '@mui/material';
import { useLanding } from 'src/contexts/LandingContext';

const BackButton = ({ sx = {}, ...props }) => {
  const { isMobile } = useLanding();

  return (
    <Button
      sx={{
        display: 'flex',
        ...sx
      }}
      {...props}
    >
      <img
        src="/static/images/icons/left-arrow.svg"
        alt="left-arrow"
        width={isMobile ? 18 : 42}
        height={isMobile ? 18 : 42}
      />

      <span
        className="default-text-shadow"
        style={{
          fontSize: isMobile ? '14px' : '20px'
        }}
      >
        Back
      </span>
    </Button>
  );
};

export default BackButton;
