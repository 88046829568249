import { Box, Grid } from '@mui/material';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const AffiliatorManagementService = () => {
  return (
    <DetailServiceTemplate
      titleWhite="Affiliator"
      titleYellow="Management"
      desc={'Our team connects you with top-performing Macro, Micro, and Nano Affiliators, ensuring diverse content creation that resonates with your target audience.'}
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *Affiliator Management* untuk bisnis saya?'
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img
            src="/static/images/services/affiliator-management/1.png"
            alt="content-1"
            style={{
              maxWidth: '600px',
              maxHeight: '287px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="/static/images/services/affiliator-management/2.png"
            alt="content-1"
            style={{
              maxWidth: '600px',
              maxHeight: '287px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
      </Grid>
    </DetailServiceTemplate>
  );
};

export default AffiliatorManagementService;
