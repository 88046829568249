import { Box } from '@mui/material';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const ECommerceEnablerService = () => {
  return (
    <DetailServiceTemplate
      titleWhite="E-Commerce"
      titleYellow="Enabler"
      desc={'we provide comprehensive solutions to optimize your online presence, enchance user experience, and drive successful transactions for your business.'}
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *E-Commerce Enabler* untuk bisnis saya?'
      }
    >
      <Box display="flex" justifyContent="center" alignItems="center" gap={3}>
        <img
          src="/static/images/services/ecommerce-enabler.png"
          alt="content-1"
          width={1345}
          height={429}
          style={{
            maxWidth: '1345px',
            maxHeight: '429px',
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>
    </DetailServiceTemplate>
  );
};

export default ECommerceEnablerService;
