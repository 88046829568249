import { Box, CardContent, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import CustomText from 'src/components/Text/CustomText';
import { useLanding } from 'src/contexts/LandingContext';
import { SERVICE_DATA } from 'src/data/local/ServicesData';
import CustomCard from './CustomCard';

const ServiceSection = () => {
  const { isMobile, kirimPesanEmojiWhatsApp } = useLanding();
  const navigate = useNavigate();

  const [leftColumnRef, leftColumnInView] = useInView({
    triggerOnce: true,
    threshold: 0.2 // 20% dari elemen harus terlihat sebelum animasi dijalankan
  });

  const [rightColumnRef, rightColumnInView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  });

  // Variabel animasi untuk elemen-elemen
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  return (
    <Box
      style={{
        background: '#202020',
        paddingTop: isMobile ? 0 : '200px',
        paddingBottom: '100px'
      }}
    >
      <Container
        style={{
          position: 'relative'
        }}
      >
        <Box textAlign="center" mb={3}>
          <CustomText type="h1">
            <span className="default-text-shadow">Our Services</span>
          </CustomText>
        </Box>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6} ref={leftColumnRef}>
            {SERVICE_DATA.slice(0, 5).map(({ service, path }, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate={leftColumnInView ? 'visible' : 'hidden'}
                variants={cardVariants}
              >
                <CustomCard
                  onClick={() => navigate(path)}
                  sx={{
                    marginBottom: '35px',
                    display: 'flex',
                    justifyContent: 'start',
                    cursor: 'pointer',
                    transition:
                      'background-color ease 500ms, transform ease 300ms',
                    ':hover': {
                      backgroundColor: '#F68724',
                      transform: 'scaleX(1.05)'
                    },
                    [':hover #service-text-1-' + index]: {
                      color: '#fff',
                      textShadow: '0px 2px 0px #000',
                      fontSize: '20px'
                    }
                  }}
                >
                  <CardContent
                    sx={{
                      padding: isMobile ? '12px !important' : undefined
                    }}
                  >
                    <CustomText
                      id={'service-text-1-' + index}
                      type="b"
                      sx={{
                        color: 'black',
                        cursor: 'pointer',
                        transition:
                          'color ease 300ms, font-size ease 300ms, text-shadow ease 300ms'
                      }}
                    >
                      {service}
                    </CustomText>
                  </CardContent>
                </CustomCard>
              </motion.div>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            ref={leftColumnRef}
            mt={0}
            sx={{
              paddingTop: isMobile ? '0px !important' : undefined
            }}
          >
            {SERVICE_DATA.slice(5, 10).map(({ service, path }, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate={leftColumnInView ? 'visible' : 'hidden'}
                variants={cardVariants}
              >
                <CustomCard
                  onClick={() => navigate(path)}
                  sx={{
                    marginBottom: '35px',
                    display: 'flex',
                    justifyContent: 'start',
                    cursor: 'pointer',
                    transition:
                      'background-color ease 500ms, transform ease 300ms',
                    ':hover': {
                      backgroundColor: '#F68724',
                      transform: 'scaleX(1.05)'
                    },
                    [':hover #service-text-2-' + index]: {
                      color: '#fff',
                      textShadow: '0px 2px 0px #000',
                      fontSize: '20px'
                    }
                  }}
                >
                  <CardContent
                    sx={{
                      padding: isMobile ? '12px !important' : undefined
                    }}
                  >
                    <CustomText
                      id={'service-text-2-' + index}
                      type="b"
                      sx={{
                        color: 'black',
                        cursor: 'pointer',
                        transition:
                          'color ease 300ms, font-size ease 300ms, text-shadow ease 300ms'
                      }}
                    >
                      {service}
                    </CustomText>
                  </CardContent>
                </CustomCard>
              </motion.div>
            ))}
          </Grid>
        </Grid>
        <Box
          position="absolute"
          style={{
            top: 0,
            left: '15%'
          }}
        >
          {!isMobile && (
            <img
              src="/static/images/background/hipros-background-icon-client.png"
              alt=""
              width="800px"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ServiceSection;
