import { ITeamPersonnel } from "src/pages/landing/components/hipros/TeamPersonelsSlider";

export const TEAM_DATA: ITeamPersonnel[] = [
  {
    name: 'Wan',
    position: 'Chief Executive Officer',
    role: 'BOD',
    img: {
      url: '/static/images/team/wan-resized.png',
      width: 200,
      height: 270
    }
  },
  {
    name: 'Dyo',
    position: 'Digital Marketing Director',
    role: 'BOD',
    img: {
      url: '/static/images/team/dyo.png',
      width: 205,
      height: 292
    }
  },
  {
    name: 'Nel',
    position: 'Creative Director',
    role: 'BOD',
    img: {
      url: '/static/images/team/nel.png',
      width: 205,
      height: 260
    }
  },
  {
    name: 'Dela',
    position: 'Content Creator',
    role: 'Content Creator',
    img: {
      url: '/static/images/team/dela.png',
      width: 200,
      height: 260
    }
  },
  {
    name: 'Zee',
    position: 'Business Development',
    role: 'Business Development',
    img: {
      url: '/static/images/team/zee.png',
      width: 175,
      height: 260
    }
  },
  {
    name: 'Cila',
    position: 'KOL & KOC Specialist',
    role: 'KOL & KOC Specialist',
    img: {
      url: '/static/images/team/cila.png',
      width: 200,
      height: 260
    }
  },
  {
    name: 'Icha',
    position: 'KOL & KOC Specialist',
    role: 'KOL & KOC Specialist',
    img: {
      url: '/static/images/team/icha.png',
      width: 180,
      height: 260
    }
  },
  {
    name: 'Dessy',
    position: 'Design Graphic',
    role: 'Design Graphic',
    img: {
      url: '/static/images/team/dessy.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Gita',
    position: 'KOL & KOC Specialist',
    role: 'KOL & KOC Specialist',
    img: {
      url: '/static/images/team/gita.png',
      width: 180,
      height: 260
    }
  },
  {
    name: 'Nana',
    position: 'Marketplace Specialist',
    role: 'Marketplace Specialist',
    img: {
      url: '/static/images/team/hana.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Sima',
    position: 'Live Streamer',
    role: 'Live Streamer',
    img: {
      url: '/static/images/team/sima.png',
      width: 170,
      height: 260
    }
  },
  {
    name: 'Hani',
    position: 'Live Streamer',
    role: 'Live Streamer',
    img: {
      url: '/static/images/team/hani.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Lev',
    position: 'Account Executive',
    role: 'Account Executive',
    img: {
      url: '/static/images/team/lev.png',
      width: 200,
      height: 260
    }
  },
  {
    name: 'Opal',
    position: 'Digital Specialist',
    role: 'Digital Specialist',
    img: {
      url: '/static/images/team/opal.png',
      width: 192,
      height: 266
    }
  },
  {
    name: 'Riza',
    position: 'Back End Developer',
    role: 'Back End Developer',
    img: {
      url: '/static/images/team/riza.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Heri',
    position: 'Back End Developer',
    role: 'Back End Developer',
    img: {
      url: '/static/images/team/heri.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Deva',
    position: 'Front End Developer',
    role: 'Front End Developer',
    img: {
      url: '/static/images/team/deva.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Hansen',
    position: 'Front End Developer',
    role: 'Front End Developer',
    img: {
      url: '/static/images/team/hansen.png',
      width: 200,
      height: 260
    }
  },
  {
    name: 'Anti',
    position: 'Business Development',
    role: 'Business Development',
    img: {
      url: '/static/images/team/anti.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Ika',
    position: 'Account Executive',
    role: 'Account Executive',
    img: {
      url: '/static/images/team/ika.png',
      width: 190,
      height: 280
    }
  },
  {
    name: 'Deanza',
    position: 'UI UX Designer',
    role: 'UI UX Designer',
    img: {
      url: '/static/images/team/deanza.png',
      width: 220,
      height: 260
    }
  },
  {
    name: 'Meli',
    position: 'Content Creator',
    role: 'Content Creator',
    img: {
      url: '/static/images/team/meli.png',
      width: 190,
      height: 260
    }
  },
  {
    name: 'Genza',
    position: 'Marketplace Specialist',
    role: 'Marketplace Specialist',
    img: {
      url: '/static/images/team/genza.png',
      width: 200,
      height: 260
    }
  },
  {
    name: 'Ian',
    position: 'Digital Specialist',
    role: 'Digital Specialist',
    img: {
      url: '/static/images/team/ian.png',
      width: 220,
      height: 280
    }
  },
  {
    name: 'Marya',
    position: 'Social Media Specialist',
    role: 'Social Media Specialist',
    img: {
      url: '/static/images/team/marya.png',
      width: 180,
      height: 260
    }
  },
  {
    name: 'Ugi',
    position: 'Digital Specialist',
    role: 'Digital Specialist',
    img: {
      url: '/static/images/team/ugi.png',
      width: 220,
      height: 260
    }
  },
  {
    name: 'Vivi',
    position: 'Live Streamer',
    role: 'Live Streamer',
    img: {
      url: '/static/images/team/vivi.png',
      width: 200,
      height: 270
    }
  },
  {
    name: 'Pipit',
    position: 'Live Streamer',
    role: 'Live Streamer',
    img: {
      url: '/static/images/team/pipit.png',
      width: 200,
      height: 280
    }
  },
  {
    name: 'Linda',
    position: 'Digital Specialist',
    role: 'Digital Specialist',
    img: {
      url: '/static/images/team/linda.png',
      width: 180,
      height: 270
    }
  },
  {
    name: 'Hana',
    position: 'Social Media Specialist',
    role: 'Social Media Specialist',
    img: {
      url: '/static/images/team/nana.png',
      width: 220,
      height: 260
    }
  },
  {
    name: 'Jeje',
    position: 'Content Creator',
    role: 'Content Creator',
    img: {
      url: '/static/images/team/jeje.png',
      width: 220,
      height: 270
    }
  },
  {
    name: 'Nu',
    position: 'Digital Specialist',
    role: 'Digital Specialist',
    img: {
      url: '/static/images/team/nu.png',
      width: 180,
      height: 260
    }
  },
  {
    name: 'Dyah',
    position: 'Account Executive',
    role: 'Account Executive',
    img: {
      url: '/static/images/team/dyah.png',
      width: 160,
      height: 260
    }
  },
  {
    name: 'Onul',
    position: 'Content Creator',
    role: 'Content Creator',
    img: {
      url: '/static/images/team/onul.png',
      width: 160,
      height: 260
    }
  },
];