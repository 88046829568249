import * as React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useLanding } from 'src/contexts/LandingContext';

export default function CustomText({ type, ...props }) {
    const { isMobile } = useLanding();

    const textShadowStyle = '2px 2px 4px rgba(0, 0, 0, 0)'; // Contoh shadow


    const H1 = styled(Typography)({
        fontFamily: 'Bebas Neue , sans-serif',
        fontSize: isMobile ? '40px' : '74px',
        fontWeight: 700,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
        lineHeight: '1.3'

    });
    const H1Custom = styled(Typography)({
        fontFamily: 'Bebas Neue , sans-serif',
        fontSize: isMobile ? '40px' : '74px',
        fontWeight: 700,
        color: '#FDD12A',
        textShadow: '4px 4px 0 #EF2C58', // Menambahkan shadow
        lineHeight: '1.3'
    });

    const H2 = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '30px' : '55px',
        fontWeight: 700,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });

    const H3 = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '26px' : '37px',
        fontWeight: 700,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });

    const H4 = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '16px' : '20px',
        fontWeight: 700,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });
    const H5 = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '14px' : '18px',
        fontWeight: 600,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });

    const P = styled(Typography)({
        fontFamily: 'Montserrat',
        fontSize: isMobile ? '12px' : '14px',
        fontWeight: 400,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });

    const B = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '12px' : '14px',
        fontWeight: 600,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });

    const SMALL = styled(Typography)({
        fontFamily: 'Poppins, sans-serif',
        fontSize: isMobile ? '9px' : '11px',
        fontWeight: 400,
        color: '#fff',
        textShadow: textShadowStyle, // Menambahkan shadow
    });
    const NAV = styled(Typography)({
        fontFamily: 'Montserrat',
        fontSize: isMobile ? '14px' : '16px',
        fontWeight: 500,
        color: '#fff',
        // textShadow: textShadowStyle, // Menambahkan shadow
    });

    switch (type) {
        case 'h1':
            return (<H1 {...props} />);
        case 'h2':
            return (<H2 {...props} />);
        case 'h3':
            return (<H3 {...props} />);
        case 'h4':
            return (<H4 {...props} />);
        case 'h5':
            return (<H5 {...props} />);
        case 'p':
            return (<P {...props} />);
        case 'b':
            return (<B {...props} />);
        case 'small':
            return (<SMALL {...props} />);
        case 'nav':
            return (<NAV {...props} />);
        case 'h1-custom':
            return (<H1Custom {...props} />);
        default:
            return null;
    }
}
