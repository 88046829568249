interface defaultQueryInterface {
    limit: number,
    page: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    search: string
}

export const defaultQuery: defaultQueryInterface = {
    limit: 10,
    page: 1,
    sortBy: 'id',
    sortOrder: 'desc',
    search: ''
}

export const defaultInitialPagination = {
    currentPage: 0,
    limit: 0,
    nextPage: 0,
    prevPage: 0,
    totalCount: 0,
    totalPages: 0,
    beginData: 0,
    endData: 0
}