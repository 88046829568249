import { Box, Card } from '@mui/material';
import { motion } from 'framer-motion';
import CustomText from 'src/components/Text/CustomText';
import { useLanding } from 'src/contexts/LandingContext';
import CustomCard from 'src/pages/landing/components/hipros/CustomCard';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

const TeamPersonnel = ({
  name,
  position,
  img,
  size = 'small',
  transitionDuration = '500ms',
  transitionDelay = '0',
  ...props
}) => {
  const { isMobile } = useLanding();

  return (
    <Card
      {...props}
      sx={{
        background: 'linear-gradient(180deg, #FDD12A 39.14%, #F68724 100%)',
        borderRadius: '0px 30px 0px 30px',
        display: 'flex',
        position: 'relative',
        width: isMobile
          ? size === 'small'
            ? '158px'
            : '178px'
          : size === 'small'
          ? '240px'
          : '282px',
        minWidth: isMobile
          ? size === 'small'
            ? '158px'
            : '178px'
          : size === 'small'
          ? '240px'
          : '282px',
        height: isMobile
          ? size === 'small'
            ? '233px'
            : '262px'
          : size === 'small'
          ? '300px'
          : '356px',
        minHeight: isMobile
          ? size === 'small'
            ? '233px'
            : '262px'
          : size === 'small'
          ? '300px'
          : '356px',
        overflow: 'visible',
        transition: 'min-height ease, min-width ease, height ease, width ease',
        transitionDuration,
        transitionDelay,

        ...props.sx
      }}
    >
      <CustomText
        type="h2"
        sx={{
          position: 'absolute',
          top: '20px',
          left: isMobile ? '6px' : '12px',
          writingMode: 'vertical-rl',
          transform: 'scale(-1)',
          fontSize: isMobile ? '80px' : '100px',
          fontFamily: '"Bebas Neue"',
          lineHeight: 1.2,
          letterSpacing: '1px',
          color: 'rgba(255, 255, 255, 0.40)'
        }}
      >
        HIPROS
      </CustomText>

      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          overflow: 'clip'
        }}
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: img.width,
            height: img.height,
            zIndex: 1
          }}
        >
          <img
            src={img.url}
            alt={name}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: isMobile ? undefined : 0,
              right: 0,
              objectFit: 'cover',
              width: img.width,
              height: img.height,
              objectPosition: '0% 0%',
              zIndex: 10,
              transformOrigin: 'bottom right',
              transform: isMobile 
                ? size === 'big' ? 'scale(0.8)' : 'scale(0.7)'
                : size === 'big' ? 'scale(1.3)' : 'none',
              transition: 'transform ease',
              transitionDuration,
              transitionDelay
            }}
            loading="lazy"
          />
          <img
            src={img.url}
            alt={name}
            style={{
              position: 'absolute',
              bottom: '-10px',
              left: '-8px',
              objectFit: 'cover',
              width: img.width,
              height: img.height,
              objectPosition: '0% 0%',
              zIndex: 0,
              transformOrigin: 'bottom right',
              transform: isMobile 
                ? size === 'big' ? 'scale(0.82)' : 'scale(0.72)'
                : size === 'big' ? 'scale(1.32)' : 'scale(1.02)',
              transition: 'transform ease',
              transitionDuration,
              transitionDelay,
              filter: 'brightness(0)',
              opacity: 0.2
            }}
            loading="lazy"
          />
        </motion.div>
      </div>

      <CustomCard
        sx={{
          position: 'absolute',
          bottom: '24px',
          right: '-40px',
          minWidth: isMobile ? '138px' : '210px',
          height: isMobile ? '42px' : '45px',
          borderRadius: '0px 20px',
          zIndex: 2
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          p={1}
          sx={{
            height: '100%',
            width: '100%'
          }}
        >
          <img
            src={img.url}
            width={isMobile ? '20px' : size === 'small' ? '32px' : '40px'}
            height={isMobile ? '20px' : size === 'small' ? '32px' : '40px'}
            style={{
              borderRadius: '50%',
              objectFit: 'cover',
              objectPosition: '0% 0%',
              aspectRatio: '1 1',
              background: '#D8D6CE',
              transition: 'width ease, height ease',
              transitionDuration,
              transitionDelay
            }}
          />

          <div style={{ flexGrow: 1 }}>
            <CustomText
              type="p"
              sx={{
                fontSize: '14px',
                color: 'black',
                fontWeight: 700
              }}
            >
              {name}
            </CustomText>
            <CustomText
              type="p"
              sx={{
                fontSize: isMobile ? '8px' : '10px',
                color: 'black'
              }}
            >
              {position}
            </CustomText>
          </div>
        </Box>
      </CustomCard>
    </Card>
  );
};

export default TeamPersonnel;
