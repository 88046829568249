import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { EmployeeProvider } from './contexts/EmployeeContext';
import BarLayout from './layouts/BarLayout';
import { employeeRoute } from './route/master/EmployeeRoute';
import { dashboardRoute } from './route/dashboard/DashboardRoute';
import { landingRoute } from './route/landing/LandingRoute';
export const Loader = (Component: any) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const LoginLayout = Loader(lazy(() => import('src/layouts/LoginLayout/LoginLayout')));




const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components
const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  // APP ---

  {
    path: 'admin',
    element: <BarLayout />,
    children: [
      ...employeeRoute(Loader),
      ...dashboardRoute(Loader)
    ]
  },
  {
    path: 'login',
    element: <LoginLayout />
  },

  // # Docs ---
  {
    path: '',
    // element: <BaseLayout />,
    children: [
      ...landingRoute(Loader),
      // { path: '/', element: <Overview /> },
      // { path: 'overview', element: <Navigate to="/" replace /> },
      { path: '*', element: <Status404 /> }
    ]
  },
 
];

export default routes;
