import { MenuItem, Select, TextField } from '@mui/material';

const CustomSelect = ({ options, ...props }) => {
  return (
    <Select
      {...props}
      inputProps={{
        sx: {
          height: '100%',
          width: '100%',
          padding: '0px',
        },
        ...props.inputProps
      }}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '30px 5px 30px 5px',
        boxShadow: '6px 8px 0px #FDD12A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 20px',

        '&:focus, &:focus-visible': {
          outline: 'none !important',
          border: 'none'
        },

        '& fieldset': {
          border: 'none',
          width: '100%'
        },

        '& input': {
          padding: '0px',
          fontSize: '18px',
          fontFamily: 'Montserrat',
          width: '100%'
        },

        ...props.sx
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
