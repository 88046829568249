import { Box, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import BackButton from 'src/components/Button/BackButton';
import CustomizedButtons from 'src/components/Button/CustomizedButtons';
import CustomText from 'src/components/Text/CustomText';
import { useLanding } from 'src/contexts/LandingContext';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

export default function DetailServiceTemplate({
  titleWhite,
  titleYellow = '',
  desc = undefined,
  placeholderWA = '',
  children
}) {
  const { isMobile, openContactForm, setActiveItem } = useLanding();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveItem('service');

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  return (
    <>
      <Box
        style={{
          background: '#202020',
          paddingTop: isMobile ? 0 : '200px',
          paddingBottom: '100px'
        }}
      >
        <Container
          style={{
            position: 'relative'
          }}
        >
          <BackButton
            sx={{
              position: 'absolute',
              top: 0,
              left: '-10px'
            }}
            onClick={() => navigate('/service')}
          />

          {/* Heading */}
          <motion.div
            key="title"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <Box textAlign="center" mb={3}>
              <CustomText type="h1" sx={{
                maxWidth: isMobile ? '240px' : undefined,
                margin: '0 auto',
              }}>
                {titleWhite}{' '}
                <span className="default-text-shadow">{titleYellow}</span>
              </CustomText>
            </Box>
          </motion.div>

          {desc && (
            <motion.div
              key="desc"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <Box textAlign="center" mb={3}>
                <CustomText
                  type="p"
                  sx={{
                    maxWidth: '1000px',
                    margin: '0 auto',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }}
                >
                  {desc}
                </CustomText>
              </Box>
            </motion.div>
          )}

          <motion.div
            key="content"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            style={{
              position: 'relative',
              zIndex: 1
            }}
          >
            <Box display="flex" mb={3} justifyContent="center">
              <CustomizedButtons
                type="primary"
                onClick={() => openContactForm()}
              >
                Contact Us Now
              </CustomizedButtons>
            </Box>
            <Box display="flex" justifyContent="center">
              {children}
            </Box>
          </motion.div>

          {!isMobile && (
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              style={{
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0
              }}
            >
              <img
                src="/static/images/icons/hipros-zigzag-blue.png"
                alt=""
                width="98px"
                height="54px"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '200px'
                }}
              />
              <img
                src="/static/images/icons/hipros-zigzag-purple.png"
                alt=""
                width="102px"
                height="66px"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '200px'
                }}
              />
              <img
                src="/static/images/icons/hipros-thunder.png"
                alt=""
                width="82px"
                height="80px"
                style={{
                  position: 'absolute',
                  top: '-50px',
                  right: '300px'
                }}
              />
            </Box>
          )}

          <Box
            position="absolute"
            style={{
              bottom: '-50px',
              left: '-50px',
              zIndex: 0
            }}
          >
            {!isMobile && (
              <img
                src="/static/images/background/hipros-background-plus-icon.png"
                alt=""
                width="78px"
                height="97px"
              />
            )}
          </Box>
        </Container>

        {!isMobile && (
          <img
            src="/static/images/background/hipros-background-icon-top-right.webp"
            alt=""
            width="236px"
            height="242px"
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
          />
        )}
      </Box>
    </>
  );
}
