export const SERVICE_DATA = [
  {
    service: 'Digital Ads',
    path: 'digital-ads'
  },
  {
    service: 'Affiliator Management',
    path: 'affiliator-management'
  },
  {
    service: 'Content Creation',
    path: 'content-creation'
  },
  {
    service: 'Live Streaming',
    path: 'livestreaming'
  },
  {
    service: 'KOL Management',
    path: 'kol-management'
  },
  {
    service: 'Brand Activation',
    path: 'brand-activation'
  },
  {
    service: 'TVC & Photo Production',
    path: 'tvc-photo-production'
  },
  {
    service: 'Apps Development',
    path: 'apps-development'
  },
  {
    service: 'Website Development',
    path: 'web-development'
  },
  {
    service: 'E-Commerce Enabler',
    path: 'ecommerce-enabler'
  }
];
