import { Grid } from '@mui/material';
import { useLanding } from 'src/contexts/LandingContext';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const DigitalAdsService = () => {
  const { isMobile } = useLanding();

  return (
    <DetailServiceTemplate
      titleWhite="Digital"
      titleYellow="Ads"
      desc={'We provide cutting-edge digital ad services tailored to ensure every dollar you spend brings measurable results.'}
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *Digital Ads* untuk bisnis saya?'
      }
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          <img
            src="/static/images/services/digital-ads/1.png"
            alt="content-1"
            width={734}
            height={489}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>

        <Grid item xs={12} xl={6} display="flex" justifyContent="center">
          <img
            src="/static/images/services/digital-ads/2.png"
            alt="content-2"
            width={635}
            height={234}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>

        <Grid item xs={6} md={3} display="flex" justifyContent="center">
          <img
            src="/static/images/services/digital-ads/3.png"
            alt="content-3"
            width={298}
            height={226}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>

        <Grid item xs={6} md={3} display="flex" justifyContent="center">
          <img
            src="/static/images/services/digital-ads/4.png"
            alt="content-4"
            width={297}
            height={226}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>
      </Grid>
    </DetailServiceTemplate>
  );
};

export default DigitalAdsService;
