import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useLanding } from 'src/contexts/LandingContext';

export default function CustomizedButtons({ type = "primary", active = true, ...props }) {
  const { isMobile } = useLanding();

  const PrimaryButton = styled(Button)({
    padding: '3px 25px',
    marginBottom: '9.5px',
    color: '#FFFFFF',            // White text color
    fontWeight: 'bold',          // Bold text
    textTransform: 'none',       // Prevents uppercase transformation
    fontSize: '16px',            // Adjust font size
    borderRadius: '50px',        // Rounded corners
    backgroundColor: '#FFD600',  // Yellow background color
    boxShadow: '6px 8px 0px #131313', // Black shadow underneath
    fontFamily: 'Montserrat',
    border: '3px solid #131313',
    position: 'relative',
    textShadow: `
      1px 2px 0 #000000,           // Black shadow to the right-bottom
      -1px -1px 0 #000000,         // Black shadow to the top-left
      1px -1px 0 #000000,          // Black shadow to the top-right
      -1px 2px 0 #000000           // Black shadow to the bottom-left
    `,
    transition: "background-color ease 500ms, padding ease 300ms, margin ease 300ms, font-size ease 300ms, box-shadow ease 300ms",

    '&:hover': {
      backgroundColor: '#F68724',  // Same yellow background on hover
      boxShadow: '5px 5px 0px 0px #131313', // Same black shadow on hover
      marginBottom: '0px',
      fontSize: "18px",
      padding: '6px 32px',
    },

    ...props.sx,
  });


  const SecondaryButton = styled(Button)({
    backgroundColor: '#030511',
    color: '#ffffff',
    padding: isMobile ? '4px 14px' : '6px 16px',
    fontSize: '0.875rem',
    borderRadius: '0px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#030511',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#030511',
    },
  });

  const NavButton = styled(Button)({
    backgroundColor: 'transparent',
    color: '#ffffff',
    padding: isMobile ? '4px 14px' : '6px 16px',
    fontSize: '0.875rem',
    borderRadius: '0px',
    // textTransform: 'none',
    // '&:hover': {
    //   backgroundColor: '#030511', 
    // },
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#030511', 
    // },
  });

  switch (type) {
    case "primary":
      return (
        <PrimaryButton {...props} variant="contained">
          {props.children}
        </PrimaryButton>
      );
    case "secondary":
      return (
        <SecondaryButton {...props} variant="contained">
          {props.children}
        </SecondaryButton>
      );
    case "nav":
      return (
        <NavButton {...props} variant="text" >
          {props.children}
        </NavButton>
      );
    default:
      return null;
  }
}
